.banner {
  padding-top: 20px;
  //border-bottom: 2px solid $brand-primary;
  font-family: $font-family-sans-serif-condensed;
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  text-align: center;
  @include media-breakpoint-up(sm)  {
    border-bottom: 1px solid rgba(255, 255, 255, 0.14);
  }
  .list-inline {
    @include media-breakpoint-down(sm)  {
      margin-bottom: 5px;
    }
  }
  .connect {
    padding: 10px 0;
    //padding: 30px;
  }
  a {
    @include hover-focus {
      color: $white;
    }
  }
}

.lucky-sign {
  width: 150px;
  margin: 0 auto 30px auto;
  position: relative;
  z-index: 10;
  @include media-breakpoint-up(sm)  {
    margin-top: -50px;
  }
  .home & {
    width: 75%;
    margin-top: 0;
    @include media-breakpoint-up(sm)  {
      width: 50%;
      margin-top: 0;
    }
    margin: auto;
  }
  .logo {
    margin: 0 auto;
  }
}

.brand {
  display: inline-block;
  padding: 1rem;

  display: none;
  .logo {
    width: 220px * 0.5;
    height: 170px * 0.5;
    fill: $text-color;
    @include media-breakpoint-up(sm)  {
      width: 220px;
      height: 170px;
    }
  }
}

.logo::selection {
  background: transparent !important;
}

.menu-item {
  padding: 10px 0;
  @include uppercase();
  font-weight: bold;
  display: inline-block;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
}
