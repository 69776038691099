body {
  font-family: $font-family-sans-serif;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  background-color: $bg-color;
  color: $text-color;
  background-image: url('../images/100-100-1-monochrome.png'), url('../images/brick-pq5.png');
  background-repeat: repeat, no-repeat;
  background-position: center top;
  background-size: auto, 200%;
  //background-size: contain;

  @include media-breakpoint-up(sm)  {
    background-size: auto, 100%;
  }
  ::selection {
    background: $brand-primary;
    color: white;
  }
  ::-moz-selection {
    background: $brand-primary;
    color: white;
  }
}

.container-fluid {
  max-width: 1300px;
  padding-right: 30px;
  padding-left: 30px;
}

body {
  //filter: blur(15px);
}

/*
.noise {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  //bottom: 0;
  pointer-events: none;
  z-index: -1;
  background-image: url('../images/100-10-5-monochrome.png');
  background-attachment: scroll;
  height: 100%;
}
*/

// Typography
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

@mixin uppercase() {
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

h3 {
  font-family: $font-family-sans-serif-condensed;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

// Something Else
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

@mixin overlay($position) {
  position: $position;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// Global Styles
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.section {
  // margin-left: -15px;
  // margin-right: -15px;
  padding: 2rem 0;
  @include media-breakpoint-up(xl) {
    padding: 2rem 15px;
  }
}

.section-header {
  padding-top: 2em;
  padding-bottom: 2em;
  margin-bottom: 0;
  font-weight: 900;
  @include uppercase();
}

.section-subheader {
  font-family: $font-family-sans-serif-condensed;
  @include uppercase();
}

.lead {
  font-family: $font-family-display;
}

a {
  transition: color 0.2s ease-in-out;
}

.icon-left {
  margin-right: 5px;
}
.icon-right {
  margin-left: 5px;
}

.btn {
  @include uppercase();
  transition: 0.2s ease-in-out;
  font-weight: 900;
  @include hover-focus {
    //outline: none;
    //box-shadow: 0px 0px 0px 2px white;
  }
}

.btn-secondary {
  background-color: transparent;
  color: white;
  @include hover-focus {
    color: $brand-primary;
    border-color: white;
    background-color: white;
  }
  &.active {
    border-color: white;
    background-color: white;
    @include hover-focus {
      color: #030303;
    }
  }
  &.disabled {
    background-color: transparent;
  }
}

.section#events {
  //background-color: $calendar-bg;
  position: relative;
}

.container {
  @media ( min-width: 1360px ) {
    max-width: 1300px;
  }
}

.connect {
  font-size: 1.5rem;
}

/*
.calendar-select {
  display: inline-block;
  position: relative;
  transition: 0.4s ease-in-out;
  //overflow: hidden;
  cursor: pointer;
  .option {
    transition: all 0s ease-in-out 0.2s, opacity 0.2s ease-in-out;
    font-size: 0.5em;
    max-height: 0em;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    opacity: 0;
      //padding: 0.5em;
  }
  &:hover {
    max-height: 3em;
    overflow: visible;
    .option {
      transition: all 0.2s ease-in-out, opacity 0.2s ease-in-out 0.2s;
      max-height: 2em;
      padding: 0.5em;
      opacity: 1;
    }
  }
  .fa {
    font-size: 0.75rem;
    vertical-align: middle;
  }
}
*/

.error404 {
  .page-header h1 {
    padding-top: 1em;
    max-width: 500px;
    margin: 0 auto;
  }
}

// Calendar / Home
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.em-calendar-wrapper {
  position: static !important;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

div#em-loading {
  position: fixed;
  transition: background-color 0.2s ease-in-out;
  background-color: rgba(black, 0.8);
  background-image: none;
  opacity: 1;
  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 4px solid white;
    border-top-color: $brand-primary;
    animation: spinner .6s linear infinite;
  }
}

.em-calnav {
  &:hover,
  &:hover:focus,
  &:active {
    text-decoration: none;
    //color: $text-color;
  }
  @include hover-focus {
    text-decoration: none;
  }
}

.calendar-header {
  @extend .section-header;
  text-align: center;
  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
  }
}

.calendar-wrap {
  //border: 1px solid $calendar-border;

  box-shadow: 0px 0px 70px black;
  @include media-breakpoint-up(sm) {
    max-width: 552px;
    margin: 0 auto;
  }
  @include media-breakpoint-up(xl) {
    max-width: none;
  }
}

.calendar-select {
  .fa {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}

.calendar-day {
  outline: 1px solid $calendar-border;
  //border-bottom: 2px solid $calendar-border;
  margin-bottom: 1rem;
  padding: 0;

  &:first-child {
    //border-left-width: 2px;
  }
  &:last-child {
    //border-right-width: 2px;
  }
  &.eventful-today,
  &.eventless-today {
    //background-color: $brand-primary;
    .day-header {
      background-color: $brand-primary;
      color: $white;
    }
  }
  &.eventless,
  &.eventless-today,
  &.eventless-pre,
  &.eventless-post {
    // @include media-breakpoint-down(lg) {}
    display: none;
  }
  &.eventless-pre,
  &.eventful-pre,
  &.eventless-post,
  &.eventful-post {
    .day-header,
    .day-content {
      background-color: $calendar-disabled;
      color: darken($calendar-disabled, 60%);
    }
  }
  .btn-group {
    position: relative;
    z-index: 10;
    margin-bottom: -27px;
    top: -13px;
    justify-content: center;
  }
  .day-content {
    height: 100%;
    background-color: $calendar-bg;
    @include media-breakpoint-up(xl) {
      //border-right: 1px solid $calendar-border;
      //border-left: 1px solid $calendar-border;
      // margin-left: -15px;
      // margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &:first-child .day-content,
  &:first-child .day-header {
    border-left-width: 0;
  }
  &:last-child .day-content,
  &:last-child .day-header {
    border-right-width: 0;
  }
  .day-header {
    padding: 15px 15px 13px 15px;
    margin-bottom: 0;
    //border-bottom: 2px solid $border-color;
    background-color: $calendar-bg;
    color: $calendar-text;
    font-family: $font-family-display;
    font-size: 1em;
    text-align: center;
    @include media-breakpoint-up(xl) {
      // margin-left: -15px;
      // margin-right: -15px;
      padding: 15px 15px 20px 15px;
      //border-right: 1px solid black;
      //border-left: 1px solid black;
    }
    .day, .date {
      @include uppercase();
      //display: block;
    }
    .day {
      //font-size: 0.5714em;
    }
    .date {
      font-size: 1em;
      // ...
    }
  }
}

.calendar-day-previous {
  opacity: 0.8;
  &:hover {
    opacity: 1.0;
  }
}

.calendar-events {
  .calendar-event {
    @include media-breakpoint-up(xl) {
      padding-top: 25px;
      // .event-time {
      //   display: none;
      // }
    }

    .event-time-label {
      z-index: 100;
      position: relative;
      display: inline-block;
      margin-bottom: 0.55rem;
      background-color: $white;
      color: $brand-primary;
      text-transform: uppercase;
      letter-spacing: .025em;
      transition: .2s ease-in-out;
      font-weight: 900;
      padding: 0.25rem 0.5rem;
      font-size: .875rem;
      border-radius: 50px;
      line-height: 1.25;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
    }

    .btn.event-time {
      z-index: 100;
      position: relative;
      margin-bottom: 0.55rem;
      border: none;
    }
  }

  .event-time-toggle {
    .btn.event-time {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 1;
      background-color: $brand-primary;
      color: white;
      margin-bottom: 0;
      cursor: pointer;

      input[type="radio"] {
        visibility: hidden;
        position: absolute;
      }
    }

    .btn.event-time:not(:disabled):not(.disabled).active,
    .btn.event-time:not(:disabled):not(.disabled):active {
      background-color: white;
      color: $brand-primary;
      cursor: default;
    }
  }
}

.event-time-toggle {
  display: none;
}

a.calendar-event {
  display: block;
  color: rgba($text-color, 0.9);
  text-shadow: 0px 0px 3px transparent;
  transition: text-shadow 0.3s ease-in-out, color 0.3s linear;
  hyphens: auto;

  @include hover-focus {
    //text-shadow: 0px 0px 2px rgba(#f4c574, 0.25), 0px 0px 10px $brand-primary;
    text-decoration: none;
    color: $text-color;
    &::before {
      // Hover change bg of calendar cell
      //background-color: rgba(black, 0.6);
    }
    .btn {
      color: $brand-primary;
      background-color: $white;
      border-color: $white;
    }
    .event-content {
      //color: $brand-primary;
    }
  }
}

.calendar-event {
  position: relative;
  overflow: hidden;
  padding: 15px;
  overflow: visible;

  background-repeat: no-repeat;
  background-size: 100%;
  background-size: cover;
  background-position: center center;

  @include media-breakpoint-up(xl) {
    margin-left: -15px;
    margin-right: -15px;
  }

  &.links-1 .event-content {
    margin-bottom: 60px;
  }
  &.links-2 .event-content {
    margin-bottom: 110px;
  }

  &::before {
    content: "";
    @include overlay(absolute);
    //right: 1px;
    //bottom: 1px;

    z-index: 2;
    background-color: rgba($calendar-overlay, 0.6);
    @supports (mix-blend-mode: multiply) {
      background-color: $calendar-overlay;
      mix-blend-mode: multiply;
    }
    transition: background-color 0.25s ease-in-out;
  }
  &::after {
    content: "";
    @include overlay(absolute);
    z-index: 3;
    background-color: rgba(#303030, 0.1);
    @supports (mix-blend-mode: color) {
     // background-color: black; // make photos black and white
      //mix-blend-mode: color;
    }
  }
  // &.hidden {
  //   @include media-breakpoint-up(xl) {
  //     display: none;
  //   }
  // }
  .event-content {
    @include clearfix();
    position: relative;
    z-index: 10; // Set Z so content sits above overlay
    //margin-bottom: 3.375rem;
    color: $calendar-text-overlay;
    a {
      display: block;
      color: rgba($text-color, 0.9);
      text-shadow: 0px 0px 3px transparent;
      transition: text-shadow 0.3s ease-in-out, color 0.3s linear;
      &:hover,
      &:focus {
        text-shadow: 0px 0px 10px lighten($brand-primary, 15%);
        text-decoration: none;
        color: $text-color;
      }
    }
  }

  img {
    //@include overlay(absolute);
    //z-index: 5;
  }
  .event-title {
    margin-bottom: 1rem;
  }
  .event-meta {

  }
  .event-name,
  .event-presented {
    @include uppercase();
    line-height: 1.25;
  }
  .event-name {
    font-weight: 900;
    margin-bottom: 0.25rem;
    font-size: 1.5em;
    line-height: 1;
  }
  .event-subtitle {
    font-family: $font-family-display;
    //font-size: 1em;
    font-size: 0.85em;
    text-transform: none;
    line-height: 1.35;
  }
  .event-presented {
    font-family: $font-family-sans-serif-condensed;
    font-size: 1em;
    margin-bottom: 0.45rem;
  }
  .event-tickets {
    //.event-time
    @include uppercase();
    line-height: 1.15;
    margin-bottom: 0.25rem;
    font-family: $font-family-sans-serif-condensed;
    font-size: 1em;
  }

  .event-link {
    margin-top: 1rem;
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;
    z-index: 10;
    .btn {
      background-color: transparent;
      border-color: $white;

      &:hover,
      &:focus {
        background-color: $white;
        color: $brand-primary;
      }
    }
    & > a {
      @include uppercase();
    }
  }
  &.no-events {
    //background-color: #232526;
    .event-content {
      color: darken($calendar-disabled, 25%);
    }
  }
  &.no-events {
    .event-content,
    .event-title,
    .event-time {
      margin-bottom: 0;
    }
    .event-name {
      font-size: 1rem;
      margin-bottom: 0;
      font-weight: 900;
      font-family: $font-family-sans-serif-condensed;
      @include uppercase();
      line-height: 1.2;
    }
    &::before,
    &::after {
      content: none;
    }
  }
}

.eventless, .eventless-today, .eventless-pre, .eventless-post {
  //opacity: 0.4;
}


// Flex things
.calendar-day {
  display: flex;
  flex-direction: column;
  .day-content {
    display: flex;
    flex: 1;
  }
  .day-header {
    flex: 0 0 0;
  }
  .calendar-events {
    //height: 100%; causes issue in safari
    display: flex;
    flex-direction: column;
    width: 100%;
    .calendar-event {
      flex: 1;
    }
  }
}


// Modals
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.modal {
  .modal-centered {
    @include overlay(absolute);
    pointer-events: none;
    display: flex;
  }
  .modal-dialog {
    width: 100%;
    margin: auto;
    pointer-events: all;
  }
  &.fade .modal-dialog {
    transition: none;
    margin: auto;
    transform: translateY(0);
    //transform: translateY(100%);
  }
  &.in {
    .modal-dialog {
      margin: auto;
      //transform: translateY(100%);
    }
  }
  .close {
    position: absolute;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    text-align: center;
    opacity: 1;
    color: $text-color;
    //background-color: $brand-primary;
    top: 0.5rem;
    right: 0.5rem;
    @include media-breakpoint-up(xl) {
      top: -3rem;
      right: -3rem;
    }

    .border,
    .border:before,
    .border:after {
      width: 2rem;
      height: 3px;
      background: $text-color;
      transform-origin: left;
    }
    .border {
      display: block;
      position:relative;
      background: transparent;
      &:before {
        content:'';
        position:absolute;
        transform: rotate(45deg);
        top: -0.65rem;
        left: 0.75rem;
      }
      &:after {
        content:'';
        position: absolute;
        transform: rotate(-45deg);
        top: 0.75rem;
        left: 0.75rem;
      }
    }
  }
}

.modal-content {
  background-color: $brand-primary;
  color: $text-color;
}

.contact-info {
  margin-bottom: 2rem;
}

.page-header h1 {
  @extend .section-header;
}

.main .alignleft {
  margin-right: 1rem;
}
