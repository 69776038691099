.content-info {
  padding: 30px 0;
  background-color: $text-color;
  //margin-top: 2rem;
  color: $bg-color;
  .connect {
    margin-bottom: 0;
  }
}

.acf-map {
  margin-top: 2rem;
  min-height: 400px;
}

.content-info .logo {
  width: 100px;
  height: 100px;
  fill: $logo-color;
}

.copyright {
  margin-bottom: 0;
}
