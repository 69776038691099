// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #f51a13;
$brand-primary:         #da2c26;
$bg-color:              #030303;
$text-color:            #fff;
$border-color:          #fff;

$theme-colors: (
  "primary": $brand-primary,
);

$logo-color:            #ed1c24;

$calendar-bg:             #fff;
$calendar-disabled:       #dfdfdf;
$calendar-section-bg:     $bg-color;
$calendar-text:           lighten(#030303, 2%);
$calendar-text-highlight: $brand-primary;
$calendar-text-overlay:   #fff;
$calendar-overlay:        $brand-primary;
$calendar-border:         #000;


// Fonts
$font-family-sans-serif:            "americane",sans-serif;
$font-family-sans-serif-condensed:  "americane-condensed",sans-serif;
$font-family-display:               "ministry",sans-serif;


$border-radius:          0;
$border-radius-lg:       50px;
$border-radius-sm:       50px;

/*
$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1300px
);
*/
